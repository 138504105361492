import React from 'react'
import './progress.css'

function Progress() {
  return (
    <div>
    <div id="loader" className="overlay">
        <div className="overlay__inner">
            <div className="overlay__content"><span className="spinner"></span></div>

        </div>

    </div>
    </div>
  )
}

export default Progress