import { async } from '@firebase/util';
import { collection, doc, getDoc, getDocs, getFirestore, query, serverTimestamp, where, writeBatch } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import ReactModal from 'react-modal';
import Modal from 'react-modal/lib/components/Modal';
import { useNavigate } from 'react-router-dom';
import { app } from '../Fire';
import Progress from '../Progress/Progress';



const AccountPage = () => {

  let arr = []

  
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const db = getFirestore(app)
    let navigate = useNavigate()

    const [mymodel, setmymodel] = useState(null)
    const [mydate, setmydate] = useState()
    const [myarray, setmyarray] = useState([])
    const [prog, setprog] = useState(false)


    useEffect(() => {
      getaccounts()
    }, [])
    
    

    async function checkaccount(){
      const docRef = doc(db, "accountsMain", ""+urlParams.get('cid')+mydate);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        alert("yes")
        navigate('/addaccount?cid='+urlParams.get('cid')+'&cdate='+mydate)
        console.log("Document data:", docSnap.data());
      } else {
      
          createaccounts()
      }
      console.log(docSnap)

    }

    async function createaccounts(){

       const q = query(collection(db, "chitUsers"), where("chitId", "==", "" + urlParams.get('cid')));
       const querysnap = await getDocs(q)
    
       const batch = writeBatch(db)
       const nycRef = doc(db, "accountsMain", ""+urlParams.get('cid')+mydate);
       batch.set(nycRef, {chitId: ""+urlParams.get('cid'), name: ""+urlParams.get('cname'), transId: mydate});
       querysnap.forEach((doc1)=>{

      
        const myref = doc(db, "accounts", ""+doc1.id+mydate);
        batch.set(myref, {Created: serverTimestamp(), agentUid: ""+doc1.data().owneruid, amount: 0, chitId: urlParams.get('cid'),
                description: "", email: ""+doc1.data().email, transId: mydate, uid: ""+doc1.data().uid});

       })

     
       await  batch.commit();
       navigate('/addaccount?cid='+urlParams.get('cid')+'&cdate='+mydate)
       alert("batch done")


    }


    async function getaccounts(){
      setprog(true)
      const q = query(collection(db, "accountsMain"), where("chitId", "==", "" + urlParams.get('cid')));
      const snapshot = await getDocs(q)
      snapshot.forEach((doc1)=>{
        console.log(doc1.data())
        arr.push(doc1.data())
      })

      setprog(false)
    
      setmyarray(arr)

    }

  return (
    <div style={{display: 'flex', padding: '12px', marginTop: '40px', flexDirection: 'column',
    }}>
        
        {

          prog ? <Progress/> : null

        }

        <button id="addaccount"
        style={{margin: '12px', padding: '8px'}}
        onClick={()=>{
            setmymodel(true)
        }}
        > Add Account</button>


        {
       mymodel ? 
       <Modal 
        
       isOpen={true}
       >
           
           <h1 onClick={()=>{
               setmymodel(false)
               setmydate(null)
           }}>x</h1>
   
         <p>Please Select a date</p>
       
       
          <div style={{padding: '20px', display: 'flex', justifyContent: 'space-between'}}>
          <input type='date' placeholder='hi' 
          onChange={(x)=>{
                setmydate(x.target.value)
             
          }}/> 

          {
            mydate != null ?  <button
            onClick={()=>{

                setprog(true)
                checkaccount()
            }}>Create</button> : null
          }
          
          </div>
        
   
       </Modal>
       : null
   }

<table id="customers"> 
                          <thead>
                    
                            <tr>
                              
                            <th>Date</th>
                              <th>Chit Id</th>
                              <th>Chit Name</th>
                             
                            </tr>
                            </thead>
                            <tbody> 
                           </tbody>

        {
                myarray.map((x, key)=>{
                    return(
                        
                    <tr 
                    onClick={()=>{
                      navigate('/addaccount?cid='+urlParams.get('cid')+'&cdate='+x.transId)
                    }}
                    key={key}>
                     

                      <td>{x.transId}</td>
                      <td>{x.chitId}</td>
                       <td>{x.name}</td>
                    </tr>
                  
       
                    )})
        }
        </table>
        
  

    </div>
  )
}

export default AccountPage