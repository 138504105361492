import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyA7FDXqFg1inpa546zCL2ZsXTLtbvJDFlI",
    authDomain: "zoomchits.firebaseapp.com",
    databaseURL: "https://zoomchits-default-rtdb.firebaseio.com",
    projectId: "zoomchits",
    storageBucket: "zoomchits.appspot.com",
    messagingSenderId: "1050816367288",
    appId: "1:1050816367288:web:cfb0bf5dcc2b6ea17c424d"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
//export const db = getFirestore(app);

//export const myauth = getAuth(app);

