import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import React, { useState } from 'react'
import { app } from '../Fire';
import Progress from '../Progress/Progress'
import './updateuser.css'


const UpdateUser = () => {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const db = getFirestore(app)

    const [pass, setpass] = useState(""+urlParams.get('pass'))
    const [prog, setprog] = useState(false)
    const [name, setname] = useState(""+urlParams.get('name'))
    const [remark, setremark] = useState(""+urlParams.get('remark'))


    function updateuser(){
        if(pass != ""){
            userservice()
        }else{
            alert("Please Enter Password")
        }
    }

    
    async function userservice() {

        setprog(true)

        const mybody = {
        "uid" : ""+urlParams.get('uid'),
        "password" : ""+pass
        }

        console.log(mybody.password)
        console.log(mybody.uid)


        await fetch('https://us-central1-zoomchits.cloudfunctions.net/onPassword', {
            method: 'POST',

            headers: {


                'Content-Type': 'application/json'
            },
            body: JSON.stringify(mybody)
        })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                if (data.status == 1) {
                alert("done")

                    updateDetails()
             
                }
                else {
                    setprog(false)
                    alert(data.message)
                    
                }


    })
    .catch((e) => {
        setprog(false)
        alert("Technical Error: " + e)
        console.log("error", e)
    })
}


    async function updateDetails(){

        try{
            const myref = doc(db, "chitUsers", ""+urlParams.get('docid'));
            await updateDoc(myref, {
               name: ''+name,
               remark: ''+remark
              });

              window.history.back()
              
        }catch(e){
            setprog(false)
            alert("Failed "+e)
        }
    }

  return (
  


    <div 
    id='formParent'
    >

    <h3>Update User</h3>

  
    {
        prog ? <Progress/> : null
    }

    <div className="container">



            <label>Update Name</label>
                <input type="text" 
                 placeholder="Update Name"
                 value={name}
                 onChange={(x)=>{
                     setname(x.target.value)
                 }}
                
                 />


                <label>Update Password</label>
                <input type="text" 
                 placeholder="Update Password"
                 value={pass}
                 defaultValue={urlParams.get('pass')}
                 onChange={(x)=>{
                     setpass(x.target.value)
                 }}
                
                 />



            <label>Update Remark</label>
                <input type="text" 
                 placeholder="Update Remark"
                 value={remark}
                 onChange={(x)=>{
                     setremark(x.target.value)
                 }}
                
                 />




            <button
            onClick={()=>{
              updateuser()
            }}
            style={{
                backgroundColor: '#04AA6D',
                color: 'white',
                padding: '6px'
                
            }}
            >Update</button>
            </div>




    </div>
    
  )
}

export default UpdateUser