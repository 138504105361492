import React, { useEffect, useState } from 'react'
import './home.css'
import { app } from '../Fire';
import { getFirestore, collection, getDocs, getDoc, where, doc, query, updateDoc } from "firebase/firestore";
import { async } from '@firebase/util';
import {getAuth, onAuthStateChanged} from "firebase/auth"
import Progress from '../Progress/Progress';
import { BrowserRouter, Link, Router, useNavigate } from 'react-router-dom';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { AiFillLock } from "react-icons/ai";
import { FaPen } from 'react-icons/fa';




function Home() {
    
  
  
  const [borderstatus, setborderstatus] = useState("")
  let uid;

 
    let navigate = useNavigate()

    const db = getFirestore(app)
    const myauth = getAuth(app)
    
    var myid;
    const [progress, setprogress] = useState(true)
    const [arr, setarr] = useState([])
    const [arr2, setarr2] = useState([])
    const [search, setsearch] = useState([])
    const [logo, setlogo] = useState()
    const [name, setname] = useState("")
    const [company, setcompany] = useState("")
    
    let myarray = []
    let myarray2 = []

    useEffect(() => {
    
    onAuthStateChanged(myauth, (user) => {
      if(user){
        uid = user.uid
        getLogo()
     
        getChitList(user.uid)
        console.log(user.uid)
      }
    })

    }, [])



    async function getLogo(){
      const docRef = doc(db, "agents", ""+uid);
      const docSnap = await getDoc(docRef);
     
      setlogo(docSnap.get('logo'))
      setname(docSnap.get('email'))

      setcompany(docSnap.data().companyName)

    }
    
    function logout(){
        console.log("out")
        myauth.signOut();
    }

    async function getChitList(uuid){

      setprogress(true)
      const q = query(collection(db, "chitGroups"), where("uid", "==", ""+uuid));

      const querySnapshot = await getDocs(q);
      setprogress(false)
   

      console.log(querySnapshot.size)
      querySnapshot.forEach((doc1)=>{
        console.log(doc1)
        myarray.push(doc1.data())
      })

      setarr(myarray)

    }


    async  function updatestatus(value, did, index, cid){

      setprogress(true)
      const washingtonRef = doc(db, "chitUsers", "" + did);

      try{

        updateDoc(washingtonRef, {
          status: value
        })
        
        setTimeout(() => {
          setprogress(false)
          getChitusers(cid)
        }, 1000);

        if(value){
          alert("Unlocked ccessful")
        }else{
          alert("Locked Successful")
        }
      
      }catch(e){
        alert("failed"+e)
      }
    
     

    }




    async function getChitusers(chitid){
      myarray2 = []
      setprogress(true)
      const q = query(collection(db, "chitUsers"), where("chitId", "==", "" + chitid));

      const querySnapshot = await getDocs(q);

      setprogress(false)

      querySnapshot.forEach((doc2)=>{
          myarray2.push(doc2)
          console.log(myarray2)
      })

      setarr2(myarray2)

    }


    return (
        <div className='maindiv' style={{padding:'20px'}}>
            {
                progress ?
                   <Progress/>

                       : null
            }

       

            <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', marginBottom: '12px' }}>

            <button onClick={()=>logout()}>Logout</button>
          

            </div>

            <div>
            <h1 style={{textAlign: 'center'}}>Home</h1>
            <p style={{textAlign: 'center', marginTop: '-30px', color: '#5a7985', fontSize: '13px' }}>({company})</p>
            </div>
           
          

        
     

      
            <div style={{display: 'flex', justifyContent: 'space-between'}}> 
      
            <div>
            <h3>Chit's List</h3>
            <button 
            className='adduser'
            onClick={()=>{
                navigate('/adduser')
              }}>Add users</button>
              <button 
              className='adduser'
              style={{marginLeft: '12px'}}
              onClick={()=>{
                navigate('/viewuser')
              }}
              >View users</button>
            </div>
            

              <div style={{ display: 'flex', flexDirection: 'column',alignItems: 'center', justifyContent: 'center'}}>
              <img 
              src={logo}
              alt="logo"
              style={{width: '100px', alignItems: 'center', borderRadius: '50px'}}
              />
              <h3><span
              style={{ color: '#5a7985', fontSize: '13px' }}
              >AgentId:- </span> {String(name).replace("@gmail.com", "")}</h3>
              </div>
           

              
            </div>

            <ScrollMenu>
              {
                arr.map((x, key)=>{
                  return(
                    <div
                    className='chitdiv'
                     style={{background: '#'+x.chitColor, width: '120px',
                    borderRadius: '6px',
                     border: x.chitName == borderstatus ? '2px solid black': '0px solid white'  ,margin: '12px', padding: '6px'}} 
                    onClick={()=>{
                      setsearch("")
                      setborderstatus(x.chitName)
                      getChitusers(x.chitId)
                    }}
                    key={key}>
                      {/* <div style={{width: '20px', height: '20px', backgroundColor: x.chitName == borderstatus ? 'black' : 'white', 
                    borderRadius: '10px'}}>

                      </div> */}
                      <p>{x.chitName}</p>
                      <p>{x.count } <b> / </b>{x.usersAllowed}</p>
                      <div style={{display: 'flex'}}>

                    <div style={{display: 'flex', flexDirection: 'column'}}>

                    <button
                    className='add'
                   onClick={()=>{
                     navigate('/addchituser?cid='+x.chitId+'&cname='+x.chitName+'&color='+x.chitColor+'&max='+x.usersAllowed)
                   }}
                    >Add</button>
                    <button
                    style={{display: 'none'}}
                      onClick={()=>{
                        navigate('/editchit?cid='+x.chitId+'&cname='+x.chitName)
                      }}
                      >edit</button>
                      <button
                       className='add'
                      onClick={()=>{
                        navigate('/accounts?cid='+x.chitId+'&cname='+x.chitName)
                      }}
                      >Accounts</button>
                    </div>
                     
                      </div>
                 
                          
                    </div>
         
                  )
                })
              }
            </ScrollMenu>

              <div 
            
              className='serachdiv'
              style={{display: 'flex', flexDirection: 'row-reverse'}}>
                <input
              
                className='searchbar'
                style={{width: '220px', display: 'none'}}
                 type='text' 
                 value={search}
                 onChange={(x)=>{
                    setsearch(x.target.value)
                 }}
                 placeholder='Search' />
              </div>
            <table 
       
            id="customers"> 
                          <thead   className='tablehead'>
                    
                            <tr>
                            <th>name</th>
                              <th>User Id</th>
                              <th>Password</th>
                          
                          
                              <th>remark</th>
                           
                     
                              <th>More</th>
                            </tr>
                            </thead>
                        <tbody>

                      
                           
                           {
                             arr2.filter((x)=>{
                               if(search == ""){
                                return x
                               }else if(x.data().email.toLowerCase().includes(search.toLowerCase())){
                                 return x
                               }
                               
                             })
                              .map((x, key)=>{
                                return (
                                  <tr key={key} >
                   
                   <td>{x.data().name}</td>
                                
                                <td>{x.data().email.replace("@gmail.com", "")}</td>
                                <td>{x.data().password}</td>
                          
                          
                               
                             
                               <td>{x.data().remark}</td>
                                
                          
                             <td>

                             <AiFillLock
                                  color={x.data().status ? 'black' : 'gray'}
                                  onClick={()=>{
                                    updatestatus(!x.data().status,x.id, key,  x.data().chitId+"")
                                  }}
                                  />

                                <FaPen
                                 style={{marginLeft: '12px'}}
                                 onClick={()=>{
                                   navigate('/updateuser?uid='+x.data().uid+'&name='+x.data().name+'&pass='+x.data().password+'&remark='+x.data().remark+'&docid='+x.id)
                                 }}
                                />
                            
                             </td>

                              </tr>
                     
                                )
                              })
                           }

</tbody>
                          </table> 

        </div>
    )
}

export default Home