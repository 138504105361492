import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { getFirestore, serverTimestamp, doc, setDoc } from 'firebase/firestore'
import React, { useState } from 'react'
import { app } from '../Fire'
import Progress from '../Progress/Progress'

const Adduser = () => {

    const db = getFirestore(app)
   let agentUid
    const myauth = getAuth(app)
    const [uid, setuid] = useState()
    const [mname, setname] = useState("")
    const [password, setpassword] = useState("")
    const [mremark, setremark] = useState("")
    const [progress, setprogress] = useState(false)


    function validate(){
   
        if(uid != "" && mname != "" && password != "", mremark != ""){
            onAuthStateChanged(myauth, function(user){
                if(user){
                    agentUid =  user.uid
                    userservice()
                }

            })
        }else{
            alert("Please Enter All Feilds")
        }
    }
    

    

    async function userservice() {
        setprogress(true)
        const mybody = {
            email: uid+ "@gmail.com",
            password: "" + password,
            name:""+mname
        }



        await fetch('https://us-central1-zoomchits.cloudfunctions.net/createNewUser', {
            method: 'POST',

            headers: {


                'Content-Type': 'application/json'
            },
            body: JSON.stringify(mybody)
        })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                if (data.status == 1) {
                    AddData(data.uid)
                   
                }
                else{
                    setprogress(false)
                 alert(data.message)
          
                }
                

            })
            .catch((e)=> {
              
                setprogress(false)
                alert("Technical Error: "+e)
                console.log("error", e)
            })
    }


    async function AddData(userid) {

        try {
            const docRef = await setDoc(doc(db, "users", "" + userid), {
                email: "" + uid+"@gmail.com",
                password: "" + password,
                remark: "" + mremark,
                createdAt: serverTimestamp(),
                agentUid: "" + agentUid,
                name: ""+mname,
                uid: userid

            })



            alert("User Added Successfully")
        
            window.history.back()
    
        } catch (e) {
            setprogress(false)
            console.error("Error adding document: ", e);
            alert("Error Creating User: " + e)
  
        }
    }

  return (
    <div style={{padding: '20px'}}>

        {
            progress ? <Progress/>: null
        }

    <h2> Add User </h2>
     <div className="container" >
         <label>User Id <span style={{fontSize: '12px', color: '#4287f5'}}> (Cannot be changed Later)</span> </label>
         <input type="text"
         onChange={(x)=>{
            setuid(x.target.value)
         }}
          placeholder="Enter User Id"/>

         <label>Enter Name</label>
         <input type="text" 
           onChange={(x)=>{
            setname(x.target.value)
         }}
          placeholder="Enter Name"/>

         <label>Enter Password</label>
         <input type="text" 
           onChange={(x)=>{
            setpassword(x.target.value)
         }}
         placeholder="Enter Password"/>

         <label>Enter Remarks</label>
         <input type="text" 
           onChange={(x)=>{
            setremark(x.target.value)
         }}
         placeholder="Enter Remarks"/>

         <button onClick={()=>{
          validate()
         }}>Submit</button>
    </div>
    </div>
  )
}

export default Adduser