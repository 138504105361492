import logo from './logo.svg';
import './App.css';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Login from './Login/Login';
import Home from './Dashboard/Home';
import { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import Adduser from './Adduser/Adduser';
import ViewUser from './ViewUser/ViewUser';
import AccountPage from './AccountPage/AccountPage';
import AddAccount from './AddAccount/AddAccount';
import Editchit from './EditChit/Editchit';
import AddChitUser from './AddChitUser/AddChitUser';
import UpdateUser from './UpdateUser/UpdateUser';

function App() {

  
  let navigate = useNavigate()
  const auth = getAuth();
  

  useEffect(() => {
    
    checkauth()
  }, [])
  


  function checkauth(){
  
  

    onAuthStateChanged(auth, (user) => {
      if (user) {
        if(window.location.pathname == '/'){
          navigate('/home')
        }
      } else {
      
       console.log("no login")
        navigate('/')
     
      }
    });
  }
  

  return (
   
    <Routes>

         <Route index element={<Login/>} />
         <Route path='/home' element={<Home/>}></Route>
         <Route path='/adduser' element={<Adduser/>}></Route>
         <Route path='/viewuser' element={<ViewUser/>}></Route>
         <Route path='/accounts' element={<AccountPage/>}></Route>
         <Route path='/addaccount' element={<AddAccount/>}></Route>
         <Route path='/editchit' element={<Editchit/>}></Route>
         <Route path='/addchituser' element={<AddChitUser/>}></Route>
         <Route path='/updateuser' element={<UpdateUser/>}></Route>
    </Routes>

  );
}

export default App;
