import React, { useEffect, useState } from 'react'
import './login.css'
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { app } from '../Fire';
import Progress from '../Progress/Progress';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, getFirestore, limit, query, where } from 'firebase/firestore';


const Login = () => {


  let db = getFirestore(app)
  let navigate = useNavigate()


  const auth = getAuth();


  

  const [email, setemail] = useState("")
  const [password, setpassword] = useState("")



  const [progress, setprogress] = useState(false)


  async function checkagent(){
    const q = query(collection(db, "agents"), where("email", "==", email+"@gmail.com"), limit(1));
    const snapshot = await getDocs(q)
    let size = snapshot.size

    alert(snapshot)
    snapshot.forEach((doc1)=>{
       if(size > 0 && doc1.data().lock == false){
          test()
        }else{
          if(doc1.data().lock == true){
            alert("You are Locked. Please contact Admin")
          }else{
            alert("Invalid Agent Id")
          }
         
        }
      
    })
   
    

  }

  function test(){
    setprogress(true)
    signInWithEmailAndPassword(auth, email+"@gmail.com", password)
        .then((userCredential) => {
          // Signed in 
          const user = userCredential.user;
          console.log(userCredential)
          setprogress(false)
          navigate('/home')
          // ...
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          alert(error)
          setprogress(false)
          // ..
        });
  }



  return (
    <div>


    {
      progress ? <Progress/>
      : null
    }
      <h1 style={{textAlign: 'center', marginBottom: '20px'}}>Agent login page (1.4)</h1>

      <form>
        <div className="container">
        <label ><b>Username</b></label>
        <input type="text"  onChange={(x)=>{setemail(x.target.value)}} placeholder="Enter Username" name="uname" required/>

        <label><b>Password</b></label>
        <input type="password" onChange={(x)=>{setpassword(x.target.value)}} placeholder="Enter Password" />
        
        </div>

        <div className="container" style={{backgroundColor: '#f1f1f1', display: 'flex',  alignItems: 'center', justifyContent: 'center'}}>
        <button
            onClick={()=>
              checkagent()
            }
        type="button" className="cancelbtn">Submit</button>
        
        </div>
        </form>

  </div>
  )
}

export default Login