import { async } from '@firebase/util'
import { FirebaseError } from 'firebase/app'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { collection, getFirestore, query, getDocs, where, doc, updateDoc } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { app } from '../Fire'
import Progress from '../Progress/Progress'

const AddAccount = () => {


    

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
    const auth = getAuth(app)
    const db = getFirestore(app)
    let arr = []
    const [myarr, setmyarr] = useState([])
    const [desc, setdesc] = useState("")
    const [amt, setamt] = useState("")
    const [prog, setprog] = useState(true)

   

    useEffect(() => {
      
        onAuthStateChanged(auth, function(user){
            if(user){
  
                loadusers()
            }
        })
    
      
    }, [])
    
    async  function loadusers(){
      const q = query(collection(db, "accounts"), where("transId", "==", ""+urlParams.get('cdate')), where("chitId", "==",""+urlParams.get('cid') ));
        
        const querySnapshot = await getDocs(q);
      
        querySnapshot.forEach((doc1)=>{
            arr.push(doc1)
        
        })
        setprog(false)
        setmyarr(arr)
      
    }

    async function updateDetails(myid, mydesc, myamount){
      const docRef = doc(db, 'accounts', ''+myid);

      await updateDoc(docRef, {
        description: mydesc,
        amount: myamount
      })

      alert("Updated")
      setprog(false)

    }


  return (
    <div style={{padding: '12px'}}>

   

      <button onClick={()=>{
        window.history.back()
      }}>back
      </button>

        {
          prog ? <Progress/> : null
        }
        <h3> Users List</h3>
        <table id="customers"> 
                          <thead>
                    
                            <tr>
                              <th>User Name</th>
                              <th>Description</th>
                              <th>Amount</th>
                              <th>Submit</th>
                            </tr>
                            </thead>
                            <tbody> 
                           </tbody>

        {
                myarr.map((x, key)=>{
                    return(
                        
                    <tr key={key}>
                      <td>{x.data().email}</td>
                      <td><input 
                    
                    defaultValue={x.data().description}
                      onChange={(x)=>{
                        setdesc(x.target.value)
                      }}
                       type="text" placeholder="description"/></td>

                       
                      <td><input 
                    
                     defaultValue={x.data().amount}
                      onChange={(x)=>{
                        
                          setamt(x.target.value)
                      }}
                       placeholder="amount"/></td>



                      <td><button
                      onClick={()=>{
                        setprog(true)
                      
                        if(amt == "" && desc == ""){
                            updateDetails(x.id, x.data().description, x.data().amount)
                        }else{
                          if(amt == ""){
                            updateDetails(x.id, desc, x.data().amount)

                          }else if(desc == ""){
                            updateDetails(x.id, x.data().description, amt)
                          }else{
                            updateDetails(x.id, desc, amt)
                          }
                        }
                    
                      }}
                      >
                        Submit</button></td> 
                    </tr>
                  
       
                    )})
        }
        </table>
    </div>
  )
}

export default AddAccount