import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { app } from '../Fire';
import Progress from '../Progress/Progress';
import './Edit.css'

const Editchit = () => {

    let agentUid;
    const [chitname, setchitname] = useState("")
   
    const [meetid, setmeetid] = useState("")
    const [meetpass, setmeetpass] = useState("")
    const [meetmsg, setmeetmsg] = useState("")

    const [progress, setprogress] = useState(true)

    const db = getFirestore(app)
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);


    const myauth = getAuth(app)


    
    useEffect(() => {
       window.history.back()

       
     // getchit() Enable this when in use (Used for chit details)
    }, [])
    

    async function getchit() {
        console.log("working")

        const docRef = doc(db, "chitGroups", "" + urlParams.get('cid'));
        const docSnap = await getDoc(docRef);


        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            setchitname(docSnap.data().chitName)
           
            setmeetid(docSnap.data().meetId)
            setmeetpass(docSnap.data().meetPassword)
            setmeetmsg(docSnap.data().meetMessage)
          

        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }

        setprogress(false)
    }



    function validate(){
   
        if(chitname != ""  && meetid != "", meetpass != "" && meetmsg != ""){
            onAuthStateChanged(myauth, function(user){
                if(user){
                    agentUid = user.uid
                 editmychit()
                }

            })
        }else{
            alert("Please Enter All Feilds")
        }
    }

    async function editmychit() {
        setprogress(true)
        const washingtonRef = doc(db, "chitGroups", "" + urlParams.get('cid'));
      
        await updateDoc(washingtonRef, {
            chitName: "" + chitname,
            meetId: meetid,
            meetPassword: meetpass,
            meetMessage: meetmsg
        });
    
        setprogress(false)
       window.history.back()
    }


  return (
    <div 
    id='formParent'
    >

    <h3>Edit Chit</h3>

    {
        progress ? <Progress/> : null
    }


    <div className="container">


    <form id="chitform">
                <label>Chit Name</label>
                <input type="text" id="chitname" name="chitname"
                value={chitname}
                onChange={(x)=>{
                    setchitname(x.target.value)
                }}
                 placeholder="Chit Name"/>

             


                <label>Meeting Id</label>
                <input type="text" 
                value={meetid}
                onChange={(x)=>{
                    setmeetid(x.target.value)
                }}
                 placeholder="Enter Meeting id"/>

                <label>Meeting Password</label>
                <input type="text"
                value={meetpass}
                onChange={(x)=>{
                    setmeetpass(x.target.value)
                }}
                 placeholder="Enter Meeting Password"/>

                <label>Meeting Message</label>
               
               <input type='text'
               value={meetmsg}
               onChange={
                   (x)=>{setmeetmsg(x.target.value)}
               }
                placeholder="Enter Meeting Message"/>




            </form>



            <button
            onClick={()=>{
                validate()
            }}
            style={{
                backgroundColor: '#04AA6D',
                color: 'white',
                padding: '6px'
                
            }}
            >Update</button>
            </div>




    </div>
  )
}

export default Editchit