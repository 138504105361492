import { async } from '@firebase/util'
import { FirebaseError } from 'firebase/app'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { collection, getFirestore, query, getDocs, where, doc } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { app } from '../Fire'
import Progress from '../Progress/Progress'

const ViewUser = () => {


    
    const auth = getAuth(app)
    let navigate = useNavigate()
    let owneruid
    const db = getFirestore(app)
    let arr = []
    const [myarr, setmyarr] = useState([])
    const [prog, setprog] = useState(true)

    const [mycolor, setmycolor] = useState([])
   
    let carray = []

    useEffect(() => {
      
        onAuthStateChanged(auth, function(user){
            if(user){
                owneruid = user.uid
                getusers()
            }
        })
    
      
    }, [])
    


    
    async  function getusers(){
        const q = query(collection(db, "users"), where("agentUid", "==", ""+owneruid));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc1)=>{
            arr.push(doc1)
            console.log(doc1.data())
         
           
        })

        setmyarr(arr)
        setprog(false)
    }

   


  return (
    <div style={{padding: '12px'}}>

      <button onClick={()=>{
        window.history.back()
      }}>back
      </button>

        {
          prog ? <Progress/> : null
        }

        
        <h3> Users List</h3>
        <table id="customers"> 
                          <thead>
                    
                            <tr>
                              <th>User Id</th>
                              <th>Password</th>
                              <th>name</th>
                              {/* <th>Update</th> */}
                              <th>Chits</th>

                            
                         
                            </tr>
                            </thead>
                            <tbody> 
                         

        {
                myarr.map((x, key)=>{
                    return(
                        
                    <tr key={key}>
                      <td>{x.data().email.replace("@gmail.com", "")}</td> 
                      <td>{x.data().password}</td> 
                      <td>{x.data().name}</td> 
                      {/* <td>
                        
                        <button
                        onClick={()=>{
                          navigate('/updateuser?uid='+x.data().uid+'&name='+x.data().name+'&pass='+x.data().password+'&remark='+x.data().remark+'&docid='+x.id)
                        }}
                        >Update</button>
                      </td> */}
                   <td>
                   {
                     x.data().colorArray != null ?
                     x.data().colorArray.map((y, key2)=>{
                       return(
                     
                      <div
                        style={{width: '10px', height: '10px', background: '#'+y,
                      display: 'flex', flexDirection: 'row', margin: '4px'}}
                         key={key2}> 
                       </div>
                  
                       )
                      
                     })
                      : <h6>No Chits</h6>
                   }
                   </td>
                    </tr>
                  
       
                    )})
        }
          </tbody>
        </table>
    </div>
  )
}

export default ViewUser