import { async } from '@firebase/util'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { arrayRemove, arrayUnion, collection, doc, getDocs, getFirestore, increment, query, where, writeBatch } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { app } from '../Fire'
import Progress from '../Progress/Progress'

const AddChitUser = () => {

   
    let agentUid;
    const auth = getAuth(app)
    const db = getFirestore(app)
    let arr = []
    const [agentid, setagentid] = useState()

    let normalarr = [];
    const [btnarr, setbtnarr] = useState([])
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const [users, setusers] = useState([])
    const [mybtn, setmybtn] = useState()
    const [prog, setprog] = useState(true)

    const chitName = urlParams.get('cname');
    const cid = urlParams.get('cid')

    
    useEffect(() => {
        onAuthStateChanged(auth, function(user){
            if(user){
                agentUid = user.uid
                LoadAllUsers()
                setagentid(user.uid)
               
                console.log("completed")
            }

        })

    }, [])
    
   
  

    async function LoadAllUsers() {

        console.log('start')
   
      const q = query(collection(db, "users"), where("agentUid", "==", ""+agentUid));

      const querySnapshot = await getDocs(q);
        
      querySnapshot.forEach((doc1)=>{

       
        
         checkuser(doc1.data().uid).then((y)=>{

            let mymodel = {mydata: doc1.data(), mystatus: y}
            console.log(mymodel)
            arr.push(mymodel)
           
        })
          
      })

      console.log("everything is added")
   
    setTimeout(() => {
       setusers(arr)
       setprog(false)
        console.log("myuser",arr)
      }, 2000);

    }

    const makeid = (length) =>  {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * 
     charactersLength));
       }
       return result;
    }

    async function addingdata( email, password, name, remarks,uid,){
      
        let m1 = makeid(20);
        const batch = writeBatch(db);
        const addref = doc(db, "chitUsers",m1);
        batch.set(addref, {
              chitId: cid,
              chitName: chitName,
              email: email,
              password: password,
              name: name,
              remark: remarks,
              status: true,
              uid: uid,
              owneruid: agentid
  
        })
  
  
        const countref = doc(db, "chitGroups", ""+urlParams.get('cid'));
         batch.update(countref, {"count": increment(1)});
  
         const colorref = doc(db, "users", uid);
         batch.update(colorref, {"colorArray": arrayUnion(""+urlParams.get('color'))});
  
        
         await batch.commit();
  
         setprog(false)
     //    window.location.reload()
       
         
          alert("Added Successfully")
        }


        async function removeuser(uid11 ){

            const q = query(collection(db, "chitUsers"), where("uid", "==", ""+uid11) , where("chitId", "==", cid));
            const querySnapshot = await getDocs(q);
            const batch = writeBatch(db);
                querySnapshot.forEach((doc1) => {

                 
                 const laRef = doc(db, "chitUsers", ""+doc1.id);
                 batch.delete(laRef);
                const countref = doc(db, "chitGroups", ""+urlParams.get('cid'));
                batch.update(countref, {"count": increment(-1)});

                const colorref = doc(db, "users", uid11);
                batch.update(colorref, {"colorArray": arrayRemove(""+urlParams.get('color'))});

             

                })
                
                try{
                  await batch.commit();
                  alert("Succesfully Removed")
                  setprog(false)
                
                  //  window.location.reload()
                }
                catch(e){
                  alert("some error "+e)
                }
               
              
        
    }



    async function checkuser(uid1){
   
  
        const q = query(collection(db, "chitUsers"), where("uid", "==", ""+uid1) , where("chitId", "==", cid));
        const querySnapshot = await getDocs(q);    
       
         const count = querySnapshot.size;
   
         console.log("working uid: "+count)
   
               if(count > 0){
                
                 return true
               }
               else{

                return false
               }
           }

     



  return (
    <div 
    style={{
        margin: '12px'
    }}
    >

    <button 
    onClick={()=>{
        window.history.back()
    }}
    >back</button>

        <div

        style={{margin: '16px'}}
        >Add User to chit {chitName}</div>

        {
            prog ? <Progress/> : null
        }

        <table id="customers"> 
         <thead>
                    
                            <tr>
                              <th>User Name</th>
                              <th>Remarks</th>
                              <th>User Id</th>
                              <th>Password</th>
                              <th>Add</th>
                    
                         
                            </tr>
                            </thead>
                        
                           
                     
                          
                           {
                              users.map((x, key)=>{
                                
                              
                           
                                return (
                              
                            
                              <tr key={key} >
                   
                               <td>{x.mydata.name}</td>
                                <td>{x.mydata.remark}</td>
                                <td>{x.mydata.email.replace("@gmail.com", "")}</td>
                                <td>{x.mydata.password}</td>
                                
                              <td>

                              

                                
                             {

                              x.mystatus ?
                               <button
                              onClick={()=>{
                               
                                const newmap = users
                                newmap[key].mystatus = false;
                              
                                setusers(newmap)
                              
                                  setprog(true)
                                  removeuser(x.mydata.uid)
                              }}
                              >-</button> : <button
                              onClick={()=>{
                                const newmap = [...users]
                                newmap[key].mystatus = true;
                              
                                setusers(newmap)

                                setprog(true)
                                  addingdata(x.mydata.email, x.mydata.password, x.mydata.name, x.mydata.remark, x.mydata.uid)
                              }}
                              >+</button>
                             }

                             

                            
                              </td>


                    
                              </tr>
                        
                              
                     
                                )
                              })
                           }


                          </table> 



    </div>
  )
}

export default AddChitUser